<template>
    <div>
        <a-col :span="9" style="margin:0 0px; text-align:center">
            <div class="phone-frame" style="max-width:350px;margin:0 auto;">
                <a class="return-btn" href="javascript:;" onclick="window.history.go(-1)">
                    <img src="/Image/return-icon.png">
                </a>
                <a class="share-btn" href="##" style="visibility:hidden;">
                    <img src="/Image/share-icon.png">
                </a>
                <iframe id="home" name="home" :src="CurrentTemplate.TemplateUrl" frameborder="0" width="100%" height="642" style="overflow:hidden;margin:65px 0px;padding-bottom:65px;"></iframe>
            </div>
            <div class="div-preview">
                <img src="../../../../public/Image/icon-phon.svg" />手机预览
                <div class="div-preview-fd">
                    <img :src="viewImgUrl" style="width:170px;height:170px;" />
                    <p>打开微信扫一扫</p>
                </div>
            </div>
        </a-col>
        <a-button type="primary" @click="AddOrder()" style="margin-top:10px;">选购</a-button>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    export default {
        name: "",
        data() {
            return {
                CurrentTemplate: {
                    TemplateDefaultUrl: "",
                    TemplateUrl: 'http://mptest.ay001.net/CommonProduct/ShopHome/IndexTemplate?CustomID=dsi201706150003&IntroducerID=uat20190910000013&userid=123456&templateid=1003',
                    TemplateID: -1,
                },
                viewImgUrl: "http://mptest.ay001.net/Introducer/CreateQrcodeHome?customerID=dsi201706150003&introducerID=uat20190910000013",
                ID: 0,
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            AddOrder() {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/AddOrder",
                    data: {
                        id: self.ID
                    },
                    OnSuccess: function (data) {
                        console.log(data);
                        self.$message.success("支付成功");
                        self.$router.push({ name: 'NewMaterial_List' });
                    }
                };
                http.Post(op);
            }
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
            this.ID = this.$route.query.id;
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>
<style type="text/css">


    .phone-frame {
        border: 1px solid #d5d5d5;
        border-radius: 10px;
        width: 100%;
        height: 648px;
        position: relative;
        overflow: hidden;
        background: url('/Image/modules.png') no-repeat top center;
        background-size: 100%;
    }

    .return-btn {
        position: absolute;
        left: 0;
        top: 30px;
    }

    .share-btn {
        position: absolute;
        right: 0;
        top: 30px;
    }

    .yj-storecon-template {
        height: auto;
        max-width: 100%;
    }

        .yj-storecon-template > div {
            position: relative;
            height: auto;
            width: auto;
            padding: 6px;
            overflow: hidden;
            text-align: center;
            flex-shrink: 0;
            font-size: 0.66rem;
        }

    .yj-storecon-template-on {
        display: none;
    }

    .yj-storecon-template > div.active .yj-storecon-template-on {
        display: block;
        position: absolute;
        content: "";
        font-size: 28px;
        z-index: 6;
        left: 50%;
        top: 50%;
        margin-left: -16px;
        margin-top: -16px;
        color: #2281F8;
        opacity: 0.8;
    }

    .yj-storecon-template > div img {
        height: 100%;
        object-fit: cover;
    }

    .yj-storecon-template > div p {
        width: 96%;
        margin-left: 2%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 1.4rem;
        line-height: 1.4rem;
        margin-bottom: 0;
    }

    .yj-storecon-template_img {
        height: 100px;
    }
    /* .yj-storecon-template > div.active p{display:none;}*/
    .yj-storecon-template > div.active img {
        transform: scale(1);
    }

    .div-preview {
        background-color: #6f6f6e;
        padding: 4px 14px;
        width: max-content;
        color: #fff;
        border-radius: 2px;
        margin: 6px auto 0;
        position: relative;
    }

        .div-preview img {
            height: 16px;
            vertical-align: middle;
            margin-top: -3px;
        }

    .div-preview-fd {
        position: absolute;
        z-index: 99999;
        top: -212px;
        left: -52px;
        width: 200px;
        height: 208px;
        background-color: #fff;
        box-shadow: #999 0 2px 6px;
        padding: 12px;
        overflow: hidden;
        display: none;
        color: #6f6f6e;
    }

        .div-preview-fd img {
            width: 100%;
            object-fit: cover;
        }

    .div-preview:hover .div-preview-fd {
        display: block;
    }
</style>
